
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { recipientOptions } from '@/definitions/_general/_data/optionsList'
import ComposeFormLine from '@/components/shared/compose/ComposeFormLine.vue'
import RecipientsEditor from '@/components/shared/compose/RecipientsEditor.vue'

export default defineComponent({
  components: {
    RecipientsEditor,
    ComposeFormLine,
    TmFormLine,
    FlowStep,
  },
  props: {
    name: {
      type: String,
    },
  },
  emits: ['update:name'],
  setup() {
    const recipient = ref([...recipientOptions.slice(0, 3)])

    return {
      recipientOptions,
      recipient,
    }
  },
})
