
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { additionalSettingsFromOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmFormLine,
    FlowStep,
  },
  setup() {
    const smsContent = ref('subject')
    const maxLengthOptions = [
      '1 x SMS (160 characters)',
      '2 x SMS (306 characters)',
      '3 x SMS (459 characters)',
      '4 x SMS (612 characters)',
      '5 x SMS (765 characters)',
      '6 x SMS (918 characters)',
    ]
    const maxLength = ref(maxLengthOptions[5])

    const from = ref(additionalSettingsFromOptions[0])

    return {
      additionalSettingsFromOptions,
      smsContent,
      maxLength,
      maxLengthOptions,
      from,
    }
  },
})
