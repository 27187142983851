
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import RecipientsStep from '@/components/pages/services/distributionLists/flow/RecipientsStep.vue'
import AdditionalSettingsStep from '@/components/pages/services/distributionLists/flow/AdditionalSettingsStep.vue'
import FinishUpStep from '@/components/pages/services/distributionLists/flow/FinishUpStep.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: { FinishUpStep, AdditionalSettingsStep, RecipientsStep, FlowStepList, TmButton, DetailsHero, PageBlock },
  props: {
    listName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const breadcrumbs = [
      { label: 'Distribution lists', name: 'base.services.distributionLists' },
      { label: props.listName || 'New distribution list' },
    ]
    const { isMdMax } = useBreakpoints()
    const currentStep = ref(1)
    const name = ref(props.listName)

    return {
      isMdMax,
      breadcrumbs,
      currentStep,
      name,
    }
  },
})
