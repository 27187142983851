
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import FinishBlock from '@/components/shared/templates/FinishBlock.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    FinishBlock,
    FlowStep,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const name = ref('mb4868641647423766@textmagic.com')

    return {
      isSmMax,
      name,
    }
  },
})
